import React from 'react';
import Modal from 'react-modal';
import classnames from 'classnames';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import checkExtraProps from '@jam3/react-check-extra-props';
import PropTypes from 'prop-types';
import { ReactComponent as Close } from '../../assets/svgs/close.svg';

export default function HelpPopup({ data, isOpen, onClose }) {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { emailLabel, nameLabel, questionLabel, requiredMessage, subTitle, submitText, title } = data;
  const [formState, setFormState] = React.useState({
    isProcessing: false,
    isComplete: false,
    fieldErrors: new Map(),
    error: null,
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="More Help Popup"
      portalClassName="LearnCLA-modal-portal"
      htmlOpenClassName="modal__html--open"
      bodyOpenClassName="modal__body--open"
      overlayClassName={{
        base: 'modal__overlay',
        afterOpen: 'modal__overlay--after-open',
        beforeClose: 'modal__overlay--before-close',
      }}
      className={{
        base: 'modal__content',
        afterOpen: 'modal__content--after-open',
        beforeClose: 'modal__content--before-close',
      }}
    >
      <div className="button-container">
        <Close className="icon close" onClick={onClose} />
      </div>

      <form
        onSubmit={async (e) => {
          e.preventDefault();

          const fieldErrors = new Map();

          setFormState({
            isProcessing: true,
            isComplete: false,
            fieldErrors,
            error: null,
          });

          const requiredFieldIds = ['requester_name', 'requester_email', 'question'];

          requiredFieldIds.forEach((id) => {
            const value = getFormValue(id);
            if (isEmptyValue(value)) {
              fieldErrors.set(id, requiredMessage);
            }
          });

          if (fieldErrors.size > 0) {
            setFormState({
              fieldErrors,
              error: new Error('Please fill in all required fields.'),
              isProcessing: false,
              isComplete: false,
            });
            return;
          }

          const reCaptchaResult = await executeRecaptcha('help_pop_up');

          const formData = {
            requester_name: getFormValue('requester_name'),
            requester_email: getFormValue('requester_email'),
            question: getFormValue('question'),
            recaptchaResponse: reCaptchaResult,
          };

          const options = {
            method: 'POST',
            headers: {
              Accept: 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          };

          fetch(process.env.WP_BACKEND_URL + process.env.LOCALE + '/wp-json/jfl/v1/cla/help', options)
            .then((res) => {
              return res.json();
            })
            .then((res) => {
              if (isResponseError(res)) {
                setFormState({
                  isProcessing: false,
                  isComplete: false,
                  error: parseResponseError(res),
                  fieldErrors,
                });
                return;
              }
              setFormState({
                isProcessing: false,
                isComplete: true,
                error: null,
                fieldErrors,
              });
              setTimeout(function () {
                onClose && onClose();
              }, 2500);
            })
            .catch((error) => {
              setFormState({
                isProcessing: false,
                isComplete: false,
                error: error,
                fieldErrors,
              });
            });
        }}
      >
        <div className="form-header">
          <h4>{title}</h4>
          <p>{subTitle}</p>
        </div>
        <div className="form-fields">
          <div className={classnames('field', { error: formState.fieldErrors.has('requester_name') })}>
            <label htmlFor="requester_name">{nameLabel + '*'}</label>
            <input type="text" id="requester_name" required />
            <div className="error-message">{formState.fieldErrors.get('requester_name')}</div>
          </div>
          <div className={classnames('field', { error: formState.fieldErrors.has('requester_email') })}>
            <label htmlFor="requester_email">{emailLabel + '*'}</label>
            <input type="email" id="requester_email" required onChange={handleChange} />
            <div className="error-message">{formState.fieldErrors.get('requester_email')}</div>
          </div>
          <div className={classnames('field', { error: formState.fieldErrors.has('question') })}>
            <label htmlFor="question">{questionLabel + '*'}</label>
            <textarea rows={8} id="question" required />
            <div className="error-message">{formState.fieldErrors.get('question')}</div>
          </div>
        </div>
        {renderFormError(formState.error)}
        <div className="form-controls">
          <button
            type="submit"
            className={classnames('submit-button', {
              processing: formState.isProcessing || formState.isComplete,
            })}
            disabled={formState.isProcessing || formState.isComplete}
          >
            {renderSubmitButtonText(submitText, formState)}
          </button>
        </div>
      </form>
    </Modal>
  );
}

function handleChange(e) {
  const emailElement = document.getElementById('requester_email');
  emailElement.value = emailElement.value.trim();
}

function renderSubmitButtonText(submitText, formState) {
  if (formState.isProcessing) {
    return 'Sending...';
  }

  if (formState.isComplete) {
    return 'Sent ✔';
  }

  return submitText;
}

function renderFormError(error) {
  if (error) {
    return (
      <div className="form-error">
        <div className="label">Error</div>
        <div className="message">{error.message || error}</div>
      </div>
    );
  }

  return null;
}

function getFormValue(id) {
  const element = document.getElementById(id);
  if (element) {
    return element.value.trim();
  }

  return null;
}

function isEmptyValue(value) {
  return !value || value.length === 0;
}

function isResponseError(res) {
  return res.code !== 'success';
}

function parseResponseError(res) {
  if (res.message) {
    return new Error(res.message);
  } else if (res.code) {
    return new Error(`An error occurred: ${res.code}`);
  } else {
    return new Error('An unknown error occurred.');
  }
}

HelpPopup.propTypes = checkExtraProps({
  data: PropTypes.shape({
    emailLabel: PropTypes.string,
    nameLabel: PropTypes.string,
    questionLabel: PropTypes.string,
    recaptchaSitekey: PropTypes.string,
    requiredMessage: PropTypes.string,
    subTitle: PropTypes.string,
    submitText: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
});
