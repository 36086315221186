import React from 'react';

import checkExtraProps from '@jam3/react-check-extra-props';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import HelpPopup from './HelpPopup';
import { customPropTypes } from '../../util';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Modal from 'react-modal';

function Help({ data, pageContext }) {
  const { helpText, helpLinkText, popup } = data;
  const [isOpen, setOpen] = React.useState(false);

  const {
    wp: { secrets },
  } = useStaticQuery(graphql`
    {
      wp {
        secrets {
          globalOptionsSecrets {
            recaptchaSitekey
          }
        }
      }
    }
  `);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={secrets?.globalOptionsSecrets?.recaptchaSitekey}>
      <div className="help">
        <span>{helpText}</span>
        &nbsp;
        <button
          className="popup-link"
          onClick={() => {
            setOpen(true);
          }}
        >
          {helpLinkText}
        </button>
        {isOpen ? (
          <HelpPopup
            data={popup}
            pageContext={pageContext}
            isOpen={isOpen}
            onClose={() => {
              setOpen(false);
            }}
          />
        ) : null}
      </div>
    </GoogleReCaptchaProvider>
  );
}

Help.propTypes = checkExtraProps({
  data: PropTypes.shape({
    ...customPropTypes.basePropTypes,
    helpLinkText: PropTypes.string,
    helpText: PropTypes.string,
    popup: PropTypes.object,
  }).isRequired,
});

export default Help;

export const fragments = graphql`
  fragment ClaHelpPopup on WpPage_Flexiblelayout_FlexibleChildren_ClaHelpPopup {
    helpLinkText
    helpText
    id
    jumpToLinkTitle
    name
    popup {
      emailLabel
      nameLabel
      questionLabel
      recaptchaSitekey
      requiredMessage
      subTitle
      submitText
      title
    }
  }

  fragment ClaHelpPopup_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_ClaHelpPopup {
    helpLinkText
    helpText
    id
    jumpToLinkTitle
    name
    popup {
      emailLabel
      nameLabel
      questionLabel
      recaptchaSitekey
      requiredMessage
      subTitle
      submitText
      title
    }
  }
`;
